/* The values for .btnAction need to be kept in sync with the constants in Contants.js*/
.btnIcon {
  font-size: 16px;
  cursor:pointer;
  background: var(--button-bg-color);
  border: 1px solid var(--button-bg-color);
  padding: 4px 4px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
}

.btnIcon:hover {
  background: var(--button-hover-color);
  border: 1px solid var(--button-hover-color);
}

.btnIconImg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
}