
.selectedUserImg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
/*  border: 1px solid #ccc;*/
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  left: 3px;
  top: -1px;
}

.selectedUserName .selectedUserImg {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  top: -1px;
  left: 8px;
}

.selectedUserInput {
  width: calc(100% - 60px);
  z-index: var(--zindex-base-over);
  position: relative;
  padding-left: 35px;
}

.selectedUserLeftSideExplainer {
  position: absolute;
  font-size: 16px;
  color: var(--primary-font-color);
  font-family: system-ui, sans-serif;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid #fff;
  top: 0px;
  left: 0px;
  width: calc(100% - 40px);
  background: var(--disabled-field-bg-color);
}

.selectedUserDisabled .textInput {
  border: 1px solid #eee;
  color: #333!important;
  -webkit-text-fill-color:#333!important;
  -webkit-opacity:1;
  opacity:1;
}

.selectedUserRightSideExplainer {
  position: absolute;
  font-size: 16px;
  color: var(--primary-font-color);
  font-family: system-ui, sans-serif;
  background: transparent;
  padding: 8px 15px;
  border: 1px solid transparent;
  top: 0px;
  right: 0px;
  width: 25px;
}

.selectedUserSearchIcon {
  position: absolute;
  top: 4px;
  left: 9px;
}

.selectedUserSearchIcon .selectedUserImg {
  border: 1px solid transparent;
  position: relative;
  left: 0px;
  top: 0px;
}
