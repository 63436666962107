

/* Primary theme */
:root {
  --body-bg-color: #fff;
  --title-bg-color: transparent;
  --menu-bg-color: #222;
  --subtitle-bg-color: transparent; /*#444;*/
  --page-headline-bg-color: #222;
  --page-section-a-bg-color: #fff;
  --page-section-b-bg-color: #f9f9f9;
  --page-section-c-bg-color: #444;
  --page-section-d-bg-color: #fcf3df;
  --page-section-e-bg-color: #eee;
  --testmode-bg-color: #B3EFFE;
  --card-bg-color: #f9f9f9;
  --content-border-color: #eee;
  --content-border-color-dark: #ccc;
  --unread-bg-color: rgb(207, 233, 244);

  --primary-font-color: #222;
  --title-font-color: #fff;
  --menu-font-color: #fff;
  --subtitle-font-color: #fff;
  --page-subtitle-font-color: #d1d2d4;
  --light-font-color: #666;
  --subtitle-dropdown-hover-color: rgb(1, 195, 227); /* #01c3e3 */

  --menu-back-font-color: rgb(1, 195, 227); /* #01c3e3 */
  --menu-selected-bg-color: #222;
  --menu-selected-font-color: rgb(1, 195, 227); /* #01c3e3 */
  --menu-border-color: #666;

  --button-bg-color: rgb(1, 195, 227); /* #01c3e3 */
  --button-font-color: #fff;
  --button-hover-color: rgb(1, 181, 209); /* #01b5d1 */
  --selectbutton-bg-color: rgb(207, 233, 244); /* #cfe9f4 */
  --selectbutton-hover-color: rgb(207, 233, 244); /* #cfe9f4 */
  --cancel-button-bg-color: #444;
  --disabled-button-bg-color: #ddd;
  --disabled-field-bg-color: #f9f9f9;
  --disabled-field-selected-bg-color: #ddd;
  --cancel-button-hover-color: #383838;
  --page-button-font-color: rgb(1, 195, 227); /* #01c3e3 */
  --expired-bg-color: #FFD4ca;
  --expired-border-color: #dd2010;

  --link-color: rgb(1, 195, 227); /* #01c3e3 */
  --markup-color: #dda010; /* rgb(227, 195, 1); */ /* #01c3e3 */
  --input-border: #ccc;
  --input-radius: 5px;
  --hot-text-color: rgb(1, 195, 227); /* #01c3e3 */
  --autosuggest-border-color: rgb(1, 195, 227); /* #01c3e3 */
  --red-color: #dd2010;
  --green-color: #1Aa239;
  --red-background-color: #FFD4ca;
  --green-background-color: #bbee99;
  --overlay-fade-bg-color: #000;
  --last-agreement-bg-color: #f0f9ff;
  --top-menu-bg: #f9f9f9;

  --zindex-overlay-content: 40;
  --zindex-overlay-fade: 38;
  --zindex-question-tooltip: 37;
  --zindex-menu-content: 36;
  --zindex-menu-fade: 35;
  --zindex-menu-bar: 34;
  --zindex-dropdown: 33;
  --zindex-feedback: 32;
  --zindex-warning-bar: 30;
  --zindex-base-raised: 5;
  --zindex-base-over: 1;
  --zindex-base-under: 0;

  --title-card-padding-top: 5px;
  --title-card-padding-bottom: 5px;
  --overlay-title-padding: 17px;
  --logo-filter: none;
  --gap-size-desktop: 20px;
  --gap-size-mobile: 5px;
  --content-max-width: 1000px;
  --overlay-max-width: 600px;
}

/* Theme with white and light colors */
/*
:root {
  --body-bg-color: #fafafa;
  --title-bg-color: #fff;
  --menu-bg-color: #444;
  --subtitle-bg-color: #fafafa;
  --page-headline-bg-color: #fafafa;
  --testmode-bg-color: #fafafa;
  --card-bg-color: #fff;
  --content-border-color: #eee;
  
  --primary-font-color: #222;
  --title-font-color: #222;
  --menu-font-color: #fff;
  --subtitle-font-color: #222;
  --page-subtitle-font-color: #666;
  --light-font-color: #666;
  --subtitle-dropdown-hover-color: rgb(1, 195, 227); 

  --menu-back-font-color: rgb(1, 195, 227);
  --menu-selected-bg-color: #444;
  --menu-selected-font-color: rgb(1, 195, 227);
  --menu-border-color: transparent;

  --title-card-padding-top: 5px;
  --title-card-padding-bottom: 5px;
  --overlay-title-padding: 17px;
  --logo-filter: invert(1.0) grayscale(100%);
  --gap-size-desktop: 20px;
  --gap-size-mobile: 20px;
}
*/

html, body {
  height:100%;
}

#root {
  position: relative;
  min-height:100%;
}

body {
  background-color: var(--body-bg-color);
  background-size: cover;
  font-family: 'Arial', sans-serif;

  /* Disables pull-to-refresh but allows overscroll glow effects. */
  /*overscroll-behavior-y: contain;*/
  /* Disables pull-to-refresh and overscroll glow effect. */
  /*overscroll-behavior-y: none;*/
}

table {
  border-collapse: collapse;
}

ul {
  margin-block-start: 0;
  margin-block-end: 0;
  padding-inline-start: 20px;
}

li {
  margin-top: 5px;
}

.pageSection {
  background: var(--page-section-a-bg-color);
  font-size: 16px;
  overflow: hidden;
  margin: 0 auto;
  padding: 80px 20px 80px 20px;
}

.pageNarrowSection {
  background: var(--page-section-a-bg-color);
  font-size: 16px;
  overflow: hidden;
  margin: 0 auto;
  padding: 20px 20px 20px 20px;
}

.pageHeadlineSection {
  background: var(--page-headline-bg-color);
  font-size: 16px;
  overflow: hidden;
  margin: 0 auto 0 auto;
  border: 1px solid var(--page-headline-bg-color);
  padding: 32px 20px 140px 20px;
  text-align: center;
}

.pageHeadline {
  font-size: 40px;
  color: var(--title-font-color);
}

.pageTitle {
  font-size: 48px;
  color: var(--primary-font-color);
  text-align: center;
}

.pageTitleWhite {
  font-size: 48px;
  color: #fff;
  text-align: center;
}

.pageGroupImg {
  margin-top: 64px;
  margin-bottom: 64px;
  display:inline-block;
  width: 700px;
  vertical-align: top;
}

.pageGroupHeadline {
  margin-top: 64px;
  display: block;
}

.restrictWidth {
  max-width: 750px;
}

.restrictSectionWidth {
  max-width: 1200px;
  margin: 0 auto;
}

.restrictSectionWidthNarrow {
  max-width: 650px;
  margin: 0 auto;
}

.preserveNewlines {
  white-space: pre-line;
  word-break: break-word;
}

.pageGroupText {
  margin-top: 64px;
  display:inline-block;
  max-width: calc(100% - 768px);
  vertical-align: top;
}

.pageText {
  font-size: 20px;
  color: var(--primary-font-color);
}

.pageHeadlineText {
  font-size: 20px;
  color: var(--title-font-color);
}

.pageImg {
  width: 320px;
}

.teamImgCnt {
  height: 150px;
  width: 150px;
  position: relative;
  overflow: hidden;
  border: 1px solid #ccc;
  border-radius: 75px;
  margin: 0px auto;
  background: #FDF7F0;
}

.teamImg {
  position: absolute;
  z-index: var(--zindex-base-under);
}

.topicImgCnt {
  height: 100px;
  width: 100px;
  position: relative;
  overflow: hidden;
}

.topicImg {
  height: 80px;
}

.bigLogoImg {
  height: 180px;
  margin-bottom:40px;
}

.mobile .jumpToTop {
  padding: 12px;
}

.pageSubHeading {
  color: var(--page-subtitle-font-color);
  font-size: 20px;
}

.pageLightText {
  color: var(--light-font-color);
  font-size: 16px;
}

.helpLightText {
  color: var(--light-font-color);
  font-size: 16px;
}

.pageWhiteText {
  color: #fff;
  font-size: 16px;
}

.mobile {
  overflow-x: hidden;
  /*padding-bottom: 50px;*/
}

.mobile .contentSection {
  font-size: 16px;
  min-width: 300px;
}

.desktop .contentSection {
  font-size: 16px;
  margin-bottom: 20px;
  min-width: 300px;
}

.loggedout .desktop .contentSection {
  margin-right: 20px;
}

.loggedin .mobile .homeSection {
  font-size: 16px;
  min-width: 300px;
  background: #fff;
}

.loggedin .desktop .homeSection {
  font-size: 16px;
  min-width: 300px;
  background: #fff;
}

.overlayDialog .contentSection {
  font-size: 16px;
  margin-right: 0px;
  margin-left: 0px;
  margin-bottom: 0px;
}

.loggedout .overlayDialog .contentSection {
  margin-right: 0px;
}

.helpCard {
  background: var(--card-bg-color);
  font-size: 16px;
  border: 1px solid var(--content-border-color);
  padding: 20px;
  border-bottom: none;
  word-break: break-word;
}

.desktop .helpCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.blankCard {
  background: transparent;
  font-size: 16px;
  border: 1px solid transparent;
  padding: 5px 20px;
  border-bottom: none;
  word-break: break-word;
}

.desktop .blankCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.seperatorCardOuter {
  border-bottom: 2px solid #ccc;
  position: relative;
  z-index: 5;
  margin-top: 5px;
}

.seperatorCardInner {
  background: transparent;
  font-size: 16px;
  border: 1px solid transparent;
  padding: 5px 20px 0px 20px;
  border-bottom: none;
  word-break: break-word;
}

.desktop .seperatorCardInner {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.profileInner {
  background: transparent;
  font-size: 16px;
  border: 1px solid transparent;
  padding: 0px 0px 0px 0px;
  border-bottom: none;
  word-break: break-word;
}

.desktop .profileInner {
  max-width: calc(var(--content-max-width) + 40px);
  margin-left: auto;
  margin-right: auto;
}

/* The values for .btn need to be kept in sync with the constants in Contants.js*/
.btn {
  font-size: 16px;
  cursor:pointer;
  background: var(--button-bg-color);
  border: 1px solid var(--button-bg-color);
  padding: 5px 15px;
  color: var(--button-font-color);
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
}

.btn:hover {
  background: var(--button-hover-color);
  border: 1px solid var(--button-hover-color);
}

.btnMenu {
  background: #fff;
  border: 1px solid var(--page-button-font-color);
  color: #222;
}

.btnMenu:hover {
  background: #eee;
  border: 1px solid var(--page-button-font-color);
}

.testmode .btnMenu {
  background: #444;
  border: none; /*1px solid #444;*/
  color: var(--page-button-font-color);
}

.testmode .btnMenu:hover {
  background: #444;
  border: none; /*1px solid #444;*/
}

.btnGeneric {
  background: var(--page-button-font-color);
  border: 1px solid var(--page-button-font-color);
  padding: 15px 15px;
  color: #fff;
  font-weight: bold;
}

.btnGeneric:hover {
  background: var(--page-button-font-color);
  border: 1px solid var(--page-button-font-color);
  padding: 15px 15px;
}

.btnGeneric:disabled {
  background: #777;
  border: 1px #777;
  padding: 15px 15px;
}

.btnAccept {
  background: #1Aa239;
  border: 1px solid #1Aa239;
}

.btnReject {
  background: #dd2010;
  border: 1px solid #dd2010;
}


.btnAccept:hover {
  background: #1A8229;
  border: 1px solid #1A8229;
}

.btnReject:hover {
  background: #aa2010;
  border: 1px solid #aa2010;
}

.moveLeft20 {
  margin-left:20px;
}

.moveLeft15 {
  margin-left:15px;
}

.moveRight5 {
  margin-right:5px;
}

.moveRight12 {
  margin-right:12px;
}

.moveRight10 {
  margin-right:10px;
}

.moveRight20 {
  margin-right:20px;
}

.moveRight40 {
  margin-right:40px;
}

.moveLeft40 {
  margin-left:40px;
}

.moveTop5 {
  margin-top:5px;
}

.moveTop10 {
  margin-top: 10px;
}

.moveBottom5 {
  margin-bottom:5px;
}

.moveTop20 {
  margin-top:20px;
}

.moveTop15 {
  margin-top:15px;
}

.moveTop30 {
  margin-top:30px;
}

.moveTop40 {
  margin-top:40px;
}

.moveTop80 {
  margin-top:80px;
}

.moveRight15 {
  margin-right: 15px;
}

.padBottom {
  height:40px;
}

.errorMessage {
  padding-top: 20px;
}

.textInput {
  background: #fff;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: var(--input-radius);
}

.textInputAutoWidth {
  width: calc(100% - 40px);
}

.textAreaInput, .textAreaSmall, .textAreaMedium {
  background: #fff;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  height: 230px;
  resize: vertical;
  border-radius: var(--input-radius);
}

.textAreaMedium {
  height: 120px;
}

.textAreaSmall {
  height: 60px;
}

.textAreaComment {
  background: #fff;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  height: 100px;
  resize: vertical;
  border-radius: var(--input-radius);
}

.labelInputBorderCnt {
  position: relative;
  padding: 20px;
  height: calc(100% - 40px);
}

.pageCardHeading {
  font-size: 24px;
  color: var(--primary-font-color);
  display: block;
}

.pageCardSmallHeading {
  font-size: 20px;
  color: var(--primary-font-color);
  display: block;
}

.helpCardHeading {
  font-size: 20px;
  color: var(--primary-font-color);
  display: block;
}

.labelInputPadLeft {
  padding-left: 90px;
}

.labelGroupInline {
  font-size: 20px;
  color: var(--primary-font-color);
  display: inline;
}

.labelInput {
  font-size: 16px;
  color: var(--primary-font-color);
  display: block;
}

.labelBold {
  font-size: 16px;
  color: var(--primary-font-color);
  display: block;
  font-weight: bold;
}

.normalText {
  font-size: 16px;
  color: var(--primary-font-color);
  display: block;
}

.smallLabel {
  font-size: 12px;
  color: var(--primary-font-color);
  display: block;
}

.disabledlink {
  color: var(--light-font-color);
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
}

.bluelink, .bluelink:active, .bluelink:visited {
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.bluelink:hover {
  color: var(--button-hover-color);
  text-decoration: none;
}

.underlinelink, .underlinelink:active, .underlinelink:visited {
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.underlinelink:hover {
  color: var(--button-hover-color);
  text-decoration: underline;
}

.darkblue, .darkblue:active, .darkblue:visited {
  color: var(--button-hover-color);  
}

.blacklink, .blacklink:active, .blacklink:visited {
  color: var(--primary-font-color);
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.blacklink:hover {
  color: var(--button-hover-color);
  text-decoration: none;
}

.whitelink, .whitelink:active, .whitelink:visited {
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.whitelink:hover {
  color: var(--button-hover-color);
  text-decoration: none;
}

.markup {
  color: var(--markup-color);
  font-weight: bold;
  text-decoration: none;
  /*cursor: pointer;*/
  background: none;
  border: none;
  padding: 0;
}

.templateInfo {
  color: var(--primary-font-color);
  font-style: italic;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
}

.templatePendingImage {
  color: var(--markup-color);
  font-weight: bold;
  text-decoration: none;
  background: none;
  border: none;
  padding: 0;
}

.loading {
  position: absolute;
  height: 40px;
  width: 40px;
  top: -8px;
  left: 92px;
}

.loadIcon {
  height: 40px;
  width: 40px;
}

.statusIcon {
  vertical-align: bottom;
  padding-top: 0px;
  height: 20px;
  width: 20px;
  margin-right: 5px;
}

.atstakeIconSmall {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  left: 3px;
  top: -1px;
}

.atstakeIconLarge {
  width: 50px;
  height: 50px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  left: 3px;
  top: -1px;
}

.statusInfoLink {
  display: inline-block;
  padding-right: 20px;
  cursor: pointer;
  color: var(--link-color);
  text-decoration: none;
}

.statusInfoLink:hover {
  color: var(--button-hover-color);
}

.padAfter10 {
  padding-right: 10px;
}

.padAfter20 {
  padding-right: 20px;
}

.padBottom20 {
  padding-bottom: 20px;
}

.padTop20 {
  padding-top: 20px;
}

.padRight20 {
  padding-right: 20px;
}

.padLeft20 {
  padding-left: 20px;
}

.padRightLarge {
  padding-right: 50px;
}

.disabled, .disabled:hover {
  background: var(--disabled-field-bg-color)!important;
  border-color: #eee!important;
  cursor: default!important;
  color: #333!important;
  -webkit-text-fill-color:#333!important;
  -webkit-opacity:1;
  opacity:1;
}

.disabledAppendRight, .disabledAppendRight:hover {
  background: transparent!important;
  border-color: #eee!important;
  cursor: default!important;
  color: #333!important;
  -webkit-text-fill-color:#333!important;
  -webkit-opacity:1;
  opacity:1;
}

.hide {
  display: none!important;
}

.dialogTable {
  color: var(--primary-font-color);
  cursor: pointer;
  border: 1px solid var(--content-border-color);
}

.dialogTable:hover {
  background: #eee;
}



.dialog, .dialogUnpadded {
  background: var(--card-bg-color);
  border: 1px solid var(--content-border-color);
  font-size: 16px;
  margin: 64px auto;
  position: relative;
}

.dialog {
  padding: 20px;
  width: 400px;
  height: 400px;
}

.dialogUnpadded {
  width: 440px;
}

.dialogTitle {
  font-size: 24px;
  font-weight: bold;
  color: var(--primary-font-color);
  padding-right: 20px;
}

.dialogTitleBorder {
  border-bottom: 2px solid #ccc;
}

.topCardRow {
  border-top: 1px solid var(--content-border-color);
  padding: 10px 20px 0 20px;
}

.middleCardRow {
  padding: 7px 20px 7px 20px;
}

.bottomCardRow {
  padding: 0 20px 10px 20px;
}

.tableCardLeft {
  cursor: pointer;
  color: var(--primary-font-color);
  padding: 10px 20px 10px 20px;
  vertical-align: top;
  background: var(--card-bg-color);
  border-top: 1px solid var(--content-border-color);
  border-left: 1px solid var(--content-border-color);
}

.tableCardMiddle {
  cursor: pointer;
  color: var(--primary-font-color);
  padding: 10px 20px 10px 20px;
  vertical-align: top;
  background: var(--card-bg-color);
  border-top: 1px solid var(--content-border-color);
}

.tableCardRight {
  cursor: pointer;
  color: var(--primary-font-color);
  padding: 10px 30px 10px 20px;
  vertical-align: top;
  background: var(--card-bg-color);
  border-top: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
}

.agreementTableCardLast td {
  background: var(--last-agreement-bg-color);
}

.tableCardHiddenRow {
  padding: 0px;
  vertical-align: top;
  padding: 5px 20px 5px 20px;
  color: var(--primary-font-color);
  background: #eee;
  text-overflow: ellipsis;
  text-decoration: line-through;
  overflow: hidden;
}

.versionCardRow {
  padding: 0 20px 15px 20px;
}

.cardHiddenRow {
  padding: 10px 20px 10px 20px;
  color: var(--primary-font-color);
  background: #eee;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-decoration: line-through;
  overflow: hidden;
}

.strongText {
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
}

.red {
  color: #dd2010;
}

.miniText {
  font-size: 12px;
  color: var(--primary-font-color);
  margin-top:5px;
}

.downArrow {
  border: solid black;
  border-width: 0 3px 3px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}

.questionHoverHelp {
  position: absolute;
  border: 1px solid var(--primary-font-color);
  padding: 8px 15px 8px 15px;
  top: -5px;
  right: 0px;
  background: #eee;
  color: var(--primary-font-color);
  font-size: 16px;
  z-index: var(--zindex-question-tooltip);
  margin: 0 10px;
}

.inputError {
  color: #dd2010;
  font-size: 12px;
}

.inputExplainer {
  color: var(--primary-font-color);
  font-size: 12px;
}

.small {
  font-size: 12px;
}

.medium {
  font-size: 20px;
}

.textBehindInput {
  position: absolute;
  top: 0px;
  left: 0px;
  color: var(--primary-font-color);
  font-size: 16px;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid transparent;
  width: 100%;
}

.textBehindRight {
  position: absolute;
  top: 2px;
  left: 0px;
  color: #888;
  font-size: 16px;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid transparent;
  width: 100%;
  text-align: right;
}

.transparent {
  background-color: transparent!important;
}

.whiteBg {
  background-color: #fff;
}

.transparentBg {
  background-color: transparent;
}

.datepickerCustom .react-datepicker__day--selected:hover, 
.datepickerCustom .react-datepicker__day--in-selecting-range:hover, 
.datepickerCustom .react-datepicker__day--in-range:hover,
.datepickerCustom .react-datepicker__day--keyboard-selected:hover {
  background-color: var(--button-hover-color);
}

.datepickerCustom .react-datepicker__day--selected, 
.datepickerCustom .react-datepicker__day--in-selecting-range, 
.datepickerCustom .react-datepicker__day--in-range,
.datepickerCustom .react-datepicker__day--keyboard-selected {
  background-color: var(--button-bg-color);
}

.react-datepicker__close-icon {
  display: none;
  visibility: hidden;
}

input {
  font-family: system-ui, sans-serif!important;
}

.linkComments {
  position: relative;
  color: var(--button-bg-color);
  display: inline-block;
  font-size: 16px;
  padding: 9px 0;
  cursor: pointer;
  margin-top: 5px;
  vertical-align: top;
}

.mobile .linkComments {
  padding: 0;
  display: block;
}

.linkComments:hover {
  color: var(--button-hover-color);
}

.versionDropdown {
  display: inline-block;
  max-width: 400px;
  width: calc(100% - 8px);
  margin-right: 5px;
  margin-top: 5px;
}

.versionDropdown .dropdownCnt {
  width: 100%;
}

.bullet {
  padding-left: 0.7em;
  text-indent: -0.7em;
}

.statusWarning {
  color: #dd2010;
  font-size: 16px;
  padding-bottom: 20px;
}

.statusWarningMild {
  color: rgb(184, 83, 0);
  font-weight: bold;
}

.warningIcon {
  position: relative;
  top: 1px;
  height: 15px;
  width: 15px;
  padding-right: 5px;
}

.minFeeMsg {
  font-size: 12px;
  color: var(--primary-font-color);
}

.grayscale img, .grayscale .btnToggleDisabled {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
}

.checkboxLabel {
  height: 16px;
}
.checkboxInput {
  vertical-align: middle;
  position: relative;
  bottom: 3px;
  margin: 0;
}

.widthAgreementTerms {
  width: calc(100% - 40px);
}

.disabledInputField {
  width: calc(100% - 40px);
  background: var(--disabled-field-bg-color);;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  border-radius: var(--input-radius);
}

.userEntry {
  background: var(--card-bg-color);
  padding: 8px 15px;
  max-width: 368px;
  width: calc(100% - 40px);
  border-top: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-right: 1px solid #ccc;
  color: var(--primary-font-color);
  cursor: default;
}

.wizardInputDatepicker {
  width: 100%;
}

.amountTwoColumnInput {
  width: calc(100% - 165px);
  vertical-align: top;
}

.contactSelectDiv {
  vertical-align: top;
}

.contentCard, .adjacentCard, .expiredCard {
  background: var(--card-bg-color);
  border-top: 1px solid var(--content-border-color);
  border-bottom: 1px solid var(--content-border-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  font-size: 16px;
  position: relative;
  padding: 20px;
  color: var(--primary-font-color);
}

.desktop .contentCard, .desktop .adjacentCard, .desktop .expiredCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.overlayDialog .contentCard, .overlayDialog .adjacentCard {
  border-left: none;
  border-right: none;
  word-wrap: break-word;
}

/*
.mobile .contentOverlay .contentCard, .mobile .contentOverlayTall .contentCard {
  overflow-x: hidden;
}
*/

.adjacentCard {
  border-bottom: none;
}

.expiredCard {
  background: var(--expired-bg-color);
  border-top: 1px solid var(--expired-border-color);
  border-bottom: 1px solid var(--expired-border-color);
  border-left: 1px solid var(--expired-border-color);
  border-right: 1px solid var(--expired-border-color);
}

.contentLabel {
  font-size: 16px;
  color: var(--primary-font-color);
  display: block;
}

.standardInput {
  width: 100%;
}

.dialogInput {
  width: 100%;
  max-width: 500px;
}

.smallWidthInput {
  width: 200px;
}

.standardWidthInput {
  width: 100%;
  max-width: 500px;
}

.fullWidthInput {
  width: 100%;
}

.fullWidthInputLinkEllipsis {
  width: 100%;
}

.fullWidthInputLinkEllipsis .textInput {
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 60px;
  width: calc(100% - 90px);
}

.sideBySide {
  display: inline-block;
  width: 50%;
}

.mobile .agreementStepCnt {
  display: none;
}

.desktop .agreementStepCnt {
  display: inline-block;
  margin-bottom: 20px;
  color: #666;
}

.agreementStep {
  display: inline-block;
}

.agreementStepArrow {
  padding: 0 5px;
  display: inline-block;
}

.agreementStepSelected {
  font-weight: bold;
  display: inline-block;
  color: var(--primary-font-color);
}

.hiddenBadge {
  font-size: 12px;
  background: #666;
  border: 1px solid #666;
  padding: 3px 5px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 2px;
  margin-bottom: 20px;
}

.hideIconCnt {
  width: 50px;
  position: absolute;
  right:2px;
  top: 8px;
  text-align: center;
}

.hideIcon {
  cursor: pointer;
  opacity: 0.75;
}

.hideIcon:hover {
  opacity: 1;
  filter: invert(1.0);
}

.agGrayText {
  color: #999;
  display: inline-block;
}

.notification {
  background: #f00;
  color: #fff;
  padding: 1px 3px 1px 3px;
  font-size: 9px;
  border-radius: 10px;
  position: relative;
  top: -8px;
  left: 2px;
}

.textAreaFullWidth, .textAreaFullWidthTall {
  background: #fff;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  width: calc(100% - 40px);
  resize: vertical;
  border-radius: var(--input-radius);
}

.textAreaFullWidth {
  height: 105px;
}

.textAreaFullWidthTall {
  height: 155px;
}