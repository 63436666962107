.reviewAgreementCnt {
  padding: 12px;
  background: #eee;
}

.reviewAgreementTitle {
  font-weight: bold;  
}

.reviewAgreementDetails {
  font-weight: bold;
}

.textAreaReview {
  background: #fff;
  border: 1px solid var(--input-border);
  display: inline-block;
  font-size: 16px;
  padding: 8px 15px;
  height: 90px;
  resize: vertical;
  border-radius: var(--input-radius);
}
