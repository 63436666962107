.desktop .titleDropdown {
  max-width: calc(var(--content-max-width) + 40px);
  margin-left: auto;
  margin-right: auto;
}

.titleDropdown .dropdownCnt {
  width: 450px;
}

.titleDropdown .dropdownTitle {
  font-size: 24px;
  font-weight: bold;
  border: 1px solid transparent;
}

.titleDropdown .dropdownTitle:hover, .titleDropdown .dropdownTitleOpen {
  border: 1px solid var(--input-border);
}

.titleDropdown .dropdownArrow, .titleDropdown .dropdownTitleOpen .dropdownArrow {
  font-size: 16px;
  position: relative;
  background: transparent;
  top: -2px;
  right: -2px;
  padding: 0px;
  z-index: var(--zindex-base-over);
  color: var(--primary-font-color);
  display: inline;
}

.titleDropdown .dropdownItem {
  font-size: 24px;
}

.titleDropdown .dropdownMenu {
  top:45px;
  max-width: 600px;
}

.gettingStartedTitle {
  font-size: 20px;
  color: var(--primary-font-color);
}

.gettingStartedCnt {
  border-radius: 10px;
  margin-top: 20px;
  border: 1px solid #999;
  box-shadow: 0px 3px 15px rgba(0,0,0,0.1);
}