.conversationCard {
  max-width: calc(var(--content-max-width) + 40px);
  color: var(--primary-font-color);
  cursor: default;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed; 
  width: calc(100% - 2px); 
  overflow-wrap: break-word;
}

.conversationCard tr {
  background: var(--card-bg-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  border-bottom: 1px solid var(--content-border-color);
  cursor: pointer;
}

.conversationUnread tr {
  background: var(--unread-bg-color);
}


.conversationUser {
  padding: 10px 20px 10px 20px;
  vertical-align: top;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.convUserSpan {
  color: var(var(--primary-font-color));
  font-weight: bold;
}

.convMessageSpan {
  color: #666;
  font-weight: normal;
}