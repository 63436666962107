.menuActionAnchor {
  position: relative;
}

.menuActionDropdown {
  position: absolute;
  top: 10px;
  right: calc(50% - 32px);
  border: 1px solid #ccc;
  background: #fff;
  width: 200px;
  padding: 0px;
  white-space: normal;
}

.topArrowOuter {
  display: block;
  position: absolute;
  top: -12px;
  right: 18px;
  width: 0;
  height: 0;
  border-left: 12px solid transparent;
  border-right: 12px solid transparent;
  border-bottom: 12px solid #ccc;
  cursor: pointer;
  z-index: 0;
}

.topArrowInner {
  display: block;
  position: absolute;
  top: -10px;
  right: 20px;
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid var(--top-menu-bg);
  cursor: pointer;
  z-index: 1;
}

.menuDropdownTop {
  padding: 13px 20px;
  font-weight: bold;
  text-align: center;
  background: var(--top-menu-bg);
}

.menuDropdownMsgEntry {
  border-top: 1px solid #ccc;
  padding: 13px 10px;
  font-size: 16px;
  vertical-align: top;
  word-break: break-word;
}

.menuDropdownImgEntry {
  border-top: 1px solid #ccc;
  padding: 13px 0px 13px 10px;
  font-size: 16px;
  width: 15px;
  vertical-align: top;
}

.menuDropdownTimeEntry {
  border-top: 1px solid #ccc;
  padding: 13px 10px 13px 0px;
  font-size: 16px;
  text-align: right;
  width: 15px;
  vertical-align: top;
}

.menuActionLink, .menuActionLink:active, .menuActionLink:visited {
  text-decoration: none;
  color: #222;
}

.menuDropdownLink, .menuActionLink:active, .menuActionLink:visited {
  text-decoration: none;
  color: #222;
  cursor: pointer;
}

.menuDropdownLink:hover, .menuDropdownLink:hover .menuDropdownMsgEntry, .menuDropdownLink:hover .menuDropdownImgEntry, .menuDropdownLink:hover .menuDropdownTimeEntry {
  color: var(--link-color);
  cursor: pointer;
}

.notifyImg {
  width: 15px;
}

.notificationWrap {
  margin-left: auto;
  margin-right: auto;
  position: relative;
  height: 0px;
  width: 0px;
  z-index: 5;
}

.notificationInner {
  position: absolute;
  top: 2px;
  left: 2px;
}