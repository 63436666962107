.dropdownCnt {
  position:relative;
  display:inline-block;
  width: calc(100% - 8px);
  z-index: var(--zindex-base-over);
}

.dropdownCntSmall {
  position:relative;
  display:inline-block;
  width: 150px;
  z-index: var(--zindex-base-over);
}

.dropdownCntMedium {
  position:relative;
  display:inline-block;
  width: 250px;
  z-index: var(--zindex-base-over);
}

.dropdownCnt:focus, .dropdownCntSmall:focus, .dropdownCntMedium:focus {
  z-index: var(--zindex-dropdown);
}

.dropdownTitle {
  position:relative;
  background: #fff;
  border: 1px solid var(--input-border);
  display: block;
  font-size: 16px;
  padding: 8px 15px;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  border-radius: var(--input-radius);
}

.dropdownTitle:hover {
  background: var(--selectbutton-hover-color);
  color: var(--primary-font-color);
}

.dropdownTitleOpen {
  background: var(--selectbutton-hover-color);
  color: var(--primary-font-color);
}

.dropdownItem {
  background: #fff;
  display: block;
  font-size: 16px;
  padding: 8px 15px;
  cursor:pointer;
  border: 1px solid var(--button-bg-color);
  border-bottom:none;
  white-space: nowrap;
  overflow: hidden;
}

.dropdownItem:hover {
  background: var(--button-bg-color);
  color: #fff;
  border-top: 1px solid var(--button-bg-color);
}

.dropdownItemKeyboard {
  background: #eee;
}

.dropdownMenu {
  position:absolute;
  top:35px;
  left:0px;
  border-bottom:1px solid var(--button-bg-color);
}

.dropdownCnt .dropdownMenu {
  max-width: 400px;
  width: 100%;
}

.dropdownCntSmall .dropdownMenu {
  max-width: 180px;
  width: 100%;
}

.dropdownCntMedium .dropdownMenu {
  max-width: 280px;
  width: 100%;
}

.dropdownArrow {
  font-size:12px;
  position:absolute;
  background:#fff;
  top:3px;
  right:0px;
  padding: 8px 15px;
  z-index: var(--zindex-base-over);
}

.disabled .dropdownArrow {
  visibility: hidden;
}

.dropdownTitle:hover .dropdownArrow, .dropdownTitleOpen .dropdownArrow  {
  background: var(--selectbutton-hover-color);
  color: var(--primary-font-color);
}
