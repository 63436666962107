.homeTopBanner {
  background: #fcf3df; /*#f0cbb0;*/
  padding: 20px 0px;
  text-align: center;
}

.homeAboutBanner {
  background: #fcf3df; /*#fdf2da;*/ /*fff1d3*/
  padding: 60px 0px;
  text-align: center;
}

.homeTopBannerTitle {
  font-size: 28px;
  padding: 0px 20px;
}

.homeAboutBannerTitle {
  font-size: 48px;
}

.homeAboutBannerSubtitle {
  margin-top: 10px;
  font-size: 16px;
}

.homeAboutBannerBottom {
  font-size: 16px;
  font-weight: bold;
}

.homeWelcomeBack {
  font-size: 24px;
}

.homeAboutReasonTitle {
  font-size: 24px;
  vertical-align: top;
}

.homeAboutReasonDesc {
  font-size: 16px;
  vertical-align: top;
}

.homeAboutBanner td {
  vertical-align: top;
}

.homeAboutCellLeft {
  padding-right: 10px;
}

.homeAboutCellMiddle {
  padding-left: 5px;
  padding-right: 5px;
}

.homeAboutCellRight {
  padding-left: 10px;
}

.marketCategoryTitle {
  border: 1px solid transparent;
  padding: var(--title-card-padding-top) 20px var(--title-card-padding-bottom) 20px;
  
}

.desktop .marketCategoryTitle {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.marketlink, .marketlink:active, .marketlink:visited {
  background: var(--title-bg-color);
  font-weight: bold;
  color: #222;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;
}

.marketlink:hover {
  color: var(--button-hover-color);
  text-decoration: underline;
}

.emailInputBox {
  max-width: 360px;
  width: calc(100% - 40px);
  padding: 12px 20px;
  display: inline-block;
  border: 1px solid #eee;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 16px;
}