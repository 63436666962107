.autosuggestEntry {
  background: #fff;
  padding: 8px 15px;
  border-left: 1px solid var(--autosuggest-border-color);
  border-bottom: 1px solid #ccc;
  border-right: 1px solid var(--autosuggest-border-color);
  color: var(--primary-font-color);
  cursor: pointer;
  position: relative;
}

.autosuggestLast .autosuggestEntry {
  border-bottom: 1px solid var(--autosuggest-border-color);
}

.autosuggestCnt {
  position: relative;
  width: calc(100% - 8px);
}

.autosuggestKeyboard {
  background: var(--selectbutton-hover-color);
}

.autosuggestNoResults .autosuggestEntry:hover {
  background: #fff;
}

.autosuggestNoResults .autosuggestEntry {
  cursor: default;
}

.autosuggestName {
  font-size: 20px;
}

.autosuggestType {
  font-style: italic;
}

.autosuggestImg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
/*  border: 1px solid #ccc;*/
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  left: 3px;
  top: -1px;
}

.autosuggestName .autosuggestImg {
  width: 35px;
  height: 35px;
  vertical-align: middle;
  /*  border: 1px solid #ccc;*/
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  top: -1px;
  left: 8px;
}

.autosuggestHighlight {
  font-weight: bold;
}

.autosuggestIdentifier {
  margin-top: 5px;
  margin-left: 10px;
}

.autosuggestResults {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: var(--zindex-base-raised);
}

.autosuggestInput {
  width: calc(100% - 60px);
  z-index: var(--zindex-base-over);
  position: relative;
  padding-left: 35px;
}

.autosuggestInputFocused {
  border: 1px solid var(--autosuggest-border-color);
}

.autosuggestLeftSideExplainer {
  position: absolute;
  font-size: 16px;
  color: var(--primary-font-color);
  font-family: system-ui, sans-serif;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid #fff;
  top: 0px;
  left: 0px;
  width: calc(100% - 40px);
}

.autosuggestDisabled .autosuggestLeftSideExplainer {
  background: var(--disabled-field-bg-color);
}

.autosuggestDisabled .textInput {
  border: 1px solid #eee;
  color: #333!important;
  -webkit-text-fill-color:#333!important;
  -webkit-opacity:1;
  opacity:1;
}

.autosuggestRightSideExplainer {
  position: absolute;
  font-size: 16px;
  color: var(--primary-font-color);
  font-family: system-ui, sans-serif;
  background: transparent;
  padding: 8px 15px;
  border: 1px solid transparent;
  top: 0px;
  right: 0px;
  width: 25px;
}

.autosuggestSearchIcon {
  position: absolute;
  top: 4px;
  left: 9px;
}

.autosuggestSearchIcon .autosuggestImg {
  border: 1px solid transparent;
  position: relative;
  left: 0px;
  top: 0px;
}

.autosuggestPadBottom {
  background-image: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0) 100%
);
  height: 10px;
  border-right: 1px solid transparent;
  border-left: 1px solid transparent;
}

.autosuggestDisplayPrice {
  font-size: 12px;
  white-space: nowrap;
}

.autosuggestStar {
  height: 15px;
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 10px;
}