/* The values for .btnToggle need to be kept in sync with the constants in Contants.js*/
.btnToggle {
  font-size: 16px;
  cursor:pointer;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #666;
  color: #666;
  vertical-align: top;
}

.btnToggleDisabled {
  font-size: 16px;
  cursor:default;
  padding: 5px 15px;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
  background: var(--disabled-field-bg-color);
  border: 1px solid #eee;
  color: #666;
  vertical-align: top;
}

.btnToggleImg {
  width: 34px;
  height: 34px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 3px;
  padding: 5px;
  vertical-align: top;
}

.btnToggle:hover {
  border: 1px solid var(--button-hover-color);
  background: var(--selectbutton-bg-color);
  color: var(--primary-font-color);
}

.btnToggleImg:hover {
  border: 1px solid var(--button-hover-color);
  background: var(--selectbutton-hover-color);
  color: var(--primary-font-color);
}

.btnToggleSelected {
  border: 1px solid var(--button-hover-color);
  background: var(--selectbutton-bg-color);
  color: var(--primary-font-color);
}

.deleteBtn .btnToggle:hover {
  border: 1px solid var(--red-color);
  background: var(--red-background-color);
  color: var(--primary-font-color);
}

.deleteBtn .btnToggleSelected {
  border: 1px solid var(--red-color);
  background: var(--red-background-color);
  color: var(--primary-font-color);
}

.btnToggleSelectedDisabled {
  border: 1px solid #333;
  background: var(--disabled-field-selected-bg-color);
  color: #333;
}

.btnWrapper {
  border: 0;
  background: transparent;
  margin: 0;
  padding: 0;
  white-space: nowrap;
  width: auto;
  height: auto;
  overflow: visible;
  margin-right: 5px;
  margin-bottom: 5px;
}

.btnToggleSelectedBlack, .btnSelectBlack:hover {
  background: var(--red-background-color);
  border: 1px solid var(--red-color);
  color: var(--primary-font-color);
}

.btnToggleSelectedGreen, .btnSelectGreen:hover {
  background: var(--green-background-color);
  border: 1px solid var(--green-color);
  color: var(--primary-font-color);
}

/* var(--button-bg-color) var(--button-hover-color) */