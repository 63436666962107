.fadeOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--overlay-fade-bg-color);
  opacity: 0.8;
  z-index: var(--zindex-overlay-fade);
}

.contentOverlay {
  position: fixed;
}

.contentOverlayTall {
  position: absolute;
}

.contentOverlay, .contentOverlayTall {
  width: 100%;
  height: 100%;
  z-index: var(--zindex-overlay-content);
  cursor: pointer;
}

.mobile .contentOverlay, .mobile .contentOverlayTall {
  width: calc(100% - 10px);
  margin-left: 5px;
}

.mobile .fadeOverlay {
  opacity: 1;
}

.hide {
  display: none;
}

.mobile .overlayDialog {
  font-size: 16px;
  margin: 0px;
  position: relative;
  width: 100%;
  max-width: var(--overlay-max-width);
  cursor: default;
}

.desktop .overlayDialog {
  font-size: 16px;
  margin: 0px;
  position: relative;
  max-width: var(--overlay-max-width);
  cursor: default;
}

.mobile .overlayPreview, .desktop .overlayPreview {
  max-width: 100%;
}

.contentOverlay .overlayDialog {
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.contentOverlayTall .overlayDialog {
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
}

.xImg {
  width: 14px;
  height: 14px;
  cursor: pointer;
  position: absolute;
  top: 22px;
  right: 19px;
  z-index: var(--zindex-base-over);
  cursor: pointer;
  background: var(--card-bg-color);
  padding: 5px;
}

.confirmTransactionMsg {
  z-index: var(--zindex-overlay-content);
  color: #fff;
  position: fixed;
  font-size: 20px;
  text-align: center;
  width: 100%;
  padding: 100px 0px;
}

.confirmTransactionLoad {
  margin-top: 20px;
}