.postingCard {
  background: #fff;
  border: 1px solid #ccc;
}

.postingCardCell {
  padding: 0px;
  vertical-align: top;
  background: var(--card-bg-color);
  cursor: pointer;
}

.postingCardExpiredCell {
  padding: 0px;
  vertical-align: top;
  background: var(--expired-bg-color);
  cursor: pointer;
}

.postingCardCell a, 
.postingCardCell a:hover, 
.postingCardCell a:active, 
.postingCardCell a:visited {
  text-decoration: none;  
}

.postingCardImgCnt {
  border: 0px solid var(--card-bg-color);
}

.postingCardImg {
  width: 100%;
}

.postingText {
  padding: 5px 10px 20px 10px;
}

.postingTitle {
  font-size: 16px;
  color: var(--primary-font-color);
}

.postingSubtitle {
  color: #666;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

.grayPosting {
  filter: grayscale(1);
}