.marketCategory {
  cursor: pointer;
}

.categoryCardCnt {
  max-width: calc(var(--content-max-width) + 40px);
  color: var(--primary-font-color);
  cursor: default;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
  table-layout: fixed; 
  width: calc(100% - 2px); 
  overflow-wrap: break-word;
}

.categoryCardCell {
  padding: 5px 0px 5px 0px;
  vertical-align: top;
  background: var(--card-bg-color);
}

.categoryGapCell {
  height: 0px;
  width: 0px;
  background: var(--card-bg-color);
}

.postingGapCell {
  height: 10px;
  width: 10px;
  background: #fff;
}

.categoryCardEmptyCell {
  background: var(--card-bg-color);
}

.categoryTitle, .categoryTitle:active, .categoryTitle:visited {
  color: var(--primary-font-color);
  font-size: 18px;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.categoryTitle:hover {
  color: var(--button-hover-color);
  text-decoration: none;
}

.categorySubtitle, .categorySubtitle:active, .categorySubtitle:visited {
  color: #666;
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.categorySubtitle:hover {
  color: var(--button-hover-color);
  text-decoration: none;
}

.marketsSearchInput {
  width: calc(100% - 40px);
}

.marketSearchBtn {
  vertical-align: top;
}

.marketTitle {
  font-size: 28px;
  padding-bottom: 10px;
  font-weight: bold;
}