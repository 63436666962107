.loadCard {
  padding: 10px 10px 5px 10px;
  background: var(--card-bg-color);
  border: 1px solid var(--content-border-color);
  max-width: calc(var(--content-max-width) + 20px);
  margin-left: auto;
  margin-right: auto;
}


.loadCardIcon {
  height: 40px;
  width: 40px;
}