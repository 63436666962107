.attestation {
  background: var(--card-bg-color);
  padding: 5px 5px 5px 8px;
  color: var(--primary-font-color);
}

.attestationIdentifier {
  position: relative;
}

.attestationValueIconCnt {
  white-space: nowrap;
  display: inline-block;
  margin-top: 5px;
  padding-right: 20px;
}

.attestationImg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border-radius: 2px;
  position: absolute;
  left: -9px;
  top: -18px;
}

.attestationTitle {
  display: block;
}

.extraInfoSpan {
  color: #999;
  display: inline-block;
  margin-top: 5px;
  padding-right: 20px;
}

.attestationAction {
  display: inline-block;
  margin-top: 5px;
}

.attestationValue {
  font-weight: bold;
}

.attestationDisabled .attestationImg {
  opacity: 0.8;
}

.attestationDisabled .attestationValue {
  text-decoration: line-through;
  font-weight: normal;
  color: #444;
}