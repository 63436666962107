/* The values for .btnAction need to be kept in sync with the constants in Contants.js*/
.btnAction {
  font-size: 16px;
  cursor:pointer;
  background: var(--button-bg-color);
  border: 1px solid var(--button-bg-color);
  padding: 5px 15px;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  border-radius: 5px;
}

.btnAction:hover {
  background: var(--button-hover-color);
  border: 1px solid var(--button-hover-color);
}

.btnActionGreen {
  background: #1Aa239;
  border: 1px solid #1Aa239;
}

.btnActionBlack, .btnActionBlack:hover {
  background: var(--cancel-button-bg-color);
  border: 1px solid var(--cancel-button-bg-color);
}

.btnActionGray {
  background: var(--disabled-button-bg-color);
  border: 1px solid var(--disabled-button-bg-color);
  cursor: default;
}

.btnActionDisabled {
  background: #666;
  border: 1px solid #666;
  cursor: default;
}

.btnActionGreen:hover {
  background: #1A8229;
  border: 1px solid #1A8229;
}

.btnActionRed:hover {
  background: var(--cancel-button-hover-color);
  border: 1px solid var(--cancel-button-hover-color);
}

.btnActionGray:hover {
  background: var(--disabled-button-bg-color);
  border: 1px solid var(--disabled-button-bg-color);
  cursor: default;
}

.btnActionDisabled:hover {
  background: #666;
  border: 1px solid #666;
}

.btnActionLight {
  background: #eee;
  border: 1px solid #eee;
  color: #222;
  padding: 11px 14px 12px 14px;
}

.btnActionLight:hover {
  background: #eee;
  border: 1px solid #eee;
  color: var(--button-hover-color);
}