.navTable {
  width: 100%;
}

.navCell {
  vertical-align: bottom;
}

.navNoWrap {
  white-space: nowrap;
}

.navLink, .navLinkSelected, .navLink:active, .navLink:visited, .navLinkSelected:active, .navLinkSelected:visited {
  padding-bottom: 5px;
  color: #222;
  border-bottom: 2px solid transparent;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;
  display: inline-block;
  position: relative;
  top: 2px;
}

.navLink:hover, .navLinkSelected {
  padding-bottom: 5px;
  color: #000;
  border-bottom: 2px solid #222;
  /*font-weight: bold;*/
}

.navText {
  padding-bottom: 5px;
  color: #666;
  border-bottom: 2px solid transparent;
  white-space: nowrap;
  display: inline-block;
  position: relative;
  top: 2px;
}

.textLeft {
  text-align: left;
  padding-right: 10px;
}

.textCenter {
  text-align: center;
  padding-right: 10px;
  padding-left: 10px;
}

.textRight {
  padding-left: 10px;
  text-align: right;
}

.menuNavImg {
  position: absolute;
  top: 5px;
  left: 0px;
  height: 20px;
}

.menuNavCnt {
  position: relative;
  width: 30px;
  height: 35px;
  display: inline-block;
  vertical-align: bottom;
  cursor: pointer;
  margin-left: 10px;
}

.homePageSearchInput {
  width: calc(100% - 40px);
}

.showHamburger .homePageSearchInput {
  width: calc(100% - 80px);
}

.navCategoriesAnchor {
  position: relative;
  height: 0px;
}

.navCategories {
  position: absolute;
  top: 2px;
  left: 0px;
  width: calc(100% - 4px);
  border-left: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  background: #fff;
}

.navColumn {
  padding: 10px 20px;
}

.navTitleRow {
  padding-top: 10px;
  padding-left: 0px;
  padding-bottom: 5px;
}

.navCrumbRow {
  padding-bottom: 10px;
}

.navNameRow {
  padding-left: 0px;
  padding-bottom: 10px;
}

.navTitle, .navTitle:active, .navTitle:visited {
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: #222;
  text-decoration: none;
}

.navName, .navName:active, .navName:visited {
  font-size: 16px;
  cursor: pointer;
  color: #222;
  text-decoration: none;
}

.navName:hover, .navTitle:hover {
  color: var(--link-color);
}
