.titleCard {
  color: #222;
  border: 1px solid transparent;
  font-size: 24px;
  padding: var(--title-card-padding-top) 20px var(--title-card-padding-bottom) 20px;
  background: var(--title-bg-color);
  font-weight: bold;
}

.desktop .titleCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.overlayDialog .titleCard {
  margin-top: 0;
  border: 1px solid var(--subtitle-bg-color);
  padding: var(--overlay-title-padding) 20px;
}

.titleCard .titleHover:hover {
  cursor: pointer;
  color: var(--subtitle-dropdown-hover-color);
}