.resolutionBlock {
  background: #ccc;
  padding: 5px 10px;
  display: inline-block;
  border-radius: 5px;
}

.reviewLikeIcon {
  width: 24px;
  position: relative;
  right: 0px;
  top: 2px;
  cursor: pointer;
  opacity: 0.75;
}

.reviewDislikeIcon {
  width: 24px;
  position: relative;
  right: 0px;
  top: 6px;
  cursor: pointer;
  opacity: 0.75;
}

.reviewLikeIcon:hover, .reviewDislikeIcon:hover {
  opacity: 1;
}
