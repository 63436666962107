
.amountUserArbitrationFeeInput {
  width: calc(100% - 165px);
  max-width: 338px;
  vertical-align: top;
}

.accountSummaryRow {
  border-top: 0px solid #eee;
}

.accountSummaryCell {
  vertical-align: top;
}

.accountSummaryLabel {
  font-weight: bold;
}

.profileImg {
  max-width: 100%;
  max-height: 100%;
  display: block;
}

.profileContainerImgInline {
  margin-top: 20px;
  margin-left: 20px;
  margin-right: 20px;
  padding-bottom: 15px;
}

.profileImgInline {
  display: block;
  border: 1px solid #ccc;
  margin-right: auto;
  margin-left: auto;
  background: var(--content-border-color);
}

.profileImgCell {
  vertical-align: top;
  background: var(--subtitle-bg-color);
  border: 1px solid var(--subtitle-bg-color);
  border-right: none;
}

.profileCntCell {
  vertical-align: middle;
  border: 1px solid transparent;/*var(--subtitle-bg-color);*/
  background: transparent;/*var(--subtitle-bg-color);*/
  color: #222;
}

.profileName {
  font-size: 20px;
  margin-top: 10px;
  margin-left: 36px;
}

.profileTopGap {
  padding-top: 20px;
}

.profileUsername {
  font-size: 36px;
  font-weight: bold;
  margin-top: 0px;
  margin-left: 10px;
}

.profileSummary {
  margin: 20px;
}

.profileActionBtnGroup {
  margin-left: 36px;
  padding-bottom: 20px;
}

.profileActionBtn {
  margin-top: 17px;
  display: inline-block;
}

.favoriteCard {
  width: 100%;
  position:relative;
}

.profileCard {
  background: transparent; /*var(--card-bg-color);*/
  width: 100%;
  table-layout: fixed;
}

.desktop .profileCard, .desktop .favoriteCard {
  max-width: calc(var(--content-max-width) + 40px);
  margin-left: auto;
  margin-right: auto;
}
