.datePickerOuter {
  /*max-width: 368px;
  width: calc(100% - 40px);*/
  width: calc(100% - 40px);
}

.datePickerOuter .react-datepicker-wrapper {
  width: 100%;
}

.datePickerOuter .react-datepicker__input-container {
  display: block;
}

.react-datepicker-popper {
  z-index: var(--zindex-base-raised)!important;
}
