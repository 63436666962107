.viewAddress {
  background: #eee;
  border: 1px solid #eee;
  cursor: default;
  color: var(--primary-font-color);
  word-wrap: break-word;
  padding: 8px 15px;
  width: calc(100% - 40px);
  max-width: 400px;
}

.addressDropdownCnt {
  width: calc(100% - 0px);
  max-width: 440px;
}