.rawContractsInput {
  width: 400px;
}

.rawContractsActionTitle {
  position: relative;
  cursor: pointer;
  padding: 10px;
  background: #eee;
}

.rawContractsActionExpand {
  padding: 5px 10px 10px 10px;
}

.rawContractsActionResponse {
  background: #fff;
  padding: 8px 15px;
  border: 1px solid #ccc;
  width: 360px;
  word-break: break-word;
}

.rawContractsArrow {
  font-size: 12px;
  position: absolute;
  top: 12px;
  right: 10px;
  color: var(--link-color);
}

.rawContractsLabel {
  font-size: 12px;
}

.rawContractsRespCnt {
  font-size: 12px;
}

.rawContractsRespLabel {
}

.rawContractsRespValue {
  font-weight: bold;
}