.graylink, .graylink:active, .graylink:visited {
  color: #999;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.graylink:hover {
  color: #999;
  text-decoration: none;
}

.howHeading {
  font-size: 18px;
  font-weight: bold;
  color: var(--primary-font-color);
  display: block;
}

.howNumber {
  position: absolute;
  font-size: 96px;
  font-weight: bold;
  color: #95d7e6;
  top: -15px;
  left: 0px;
  z-index: var(--zindex-base-under);
}

.howPadLeftNumber {
  padding-left: 90px;
  z-index: var(--zindex-base-over);
  position: relative;
}