.agreementCardCnt {
  position: relative;
  width: 100%;
}

.desktop .agreementCardCnt {
  max-width: calc(var(--content-max-width) + 40px);
  margin-left: auto;
  margin-right: auto;
}

.agreementCard {
  background: var(--card-bg-color);
  position: relative;
  cursor: pointer;
}

.agreementCardLast {
  background: var(--last-agreement-bg-color);
}

.versionCard {
  cursor: pointer;
  color: var(--primary-font-color);
  border: 1px solid var(--content-border-color);
  background: var(--card-bg-color);
  position: relative;
  border-bottom: none;
}

.agLightText {
  color: var(--light-font-color);
  display: inline-block;
}

.agStrongText {
  font-weight: bold;
  display: inline-block;
}

.agCardEmphasisText {
  color: var(--hot-text-color);
  display: inline-block;
}

.agCardGrayText {
  color: #999;
  display: inline-block;
  word-break: break-word;
}

.cardHiddenRow .agCardEmphasisText, .tableCardHiddenRow .agCardEmphasisText {
  color: var(--hot-text-color);
  display: inline;
}

.cardHiddenRow .agCardGrayText, .tableCardHiddenRow .agCardGrayText {
  color: #999;
  display: inline;
}

.badgeRequest {
  font-weight: bold;
  white-space: nowrap;
  display: inline-block;
  margin-top: 20px;
  margin-right: 5px;
}

.cardHideIcon, .cardShowIcon {
  width: 18px;
  position: absolute;
  cursor: pointer;
  opacity: 0.75;
  padding-right: 6px;
  padding-top: 6px;
  right: 0px;
  top: 0px;
}

.cardHideIcon:hover, .cardShowIcon:hover {
  opacity: 1;
  filter: invert(1.0);
}