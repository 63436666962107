.graylink, .graylink:active, .graylink:visited {
  color: #999;
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.graylink:hover {
  color: #999;
  text-decoration: none;
}

.faqHeading {
  font-size: 24px;
  color: var(--primary-font-color);
  display: block;
}

.faqLightText {
  color: var(--light-font-color);
  font-size: 18px;
}