.menuPlaceholder {
  display:none;
  height: 0px;
  /*height: 64px;*/
}

.menuBar {
  background-color: var(--title-bg-color);
  height: 64px;
  color: var(--menu-back-font-color);
  position: fixed;
  z-index: var(--zindex-menu-bar);
  white-space: nowrap;
  width: 100%;
}

.testmode .menuBar {
  background: var(--testmode-bg-color);
}

.loggedin .desktop .menuBar {
  width: calc(100% - 261px);
  border-right: 1px solid var(--menu-border-color);
}

.testmode .desktop .menuBar {
  border-right-color: var(--testmode-bg-color);
}

.newMenuInner {
  margin: 0 14px;
  padding-top: 14px;
}

.desktop .newMenuInner {
  margin: 0 32px;
  padding-top: 14px;
}

.menuPolyCnt {
  display: inline-block;
  color: #666;
  position: relative;
  top: -2px;
  left: 2px;
}

.menuPolySvg {
  width: 9px;
  height: 5px;
}

.menuPolyTri {
  fill: #666;
  stroke:#666;
}

.menuBtn {
  padding: 8px 15px;
  cursor: pointer;
  display: inline-block;
  background: transparent;
  font-size: 16px;
  color: var(--subtitle-bg-color);
}

.menuBtn:hover {
  color: var(--menu-font-color);
}

.selectedBtn {
  background: transparent;
  color: var(--menu-font-color);
}

.menuInnerRight {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 14px;
  background: var(--title-bg-color);
  padding-top: 14px;
  z-index: var(--zindex-base-over);
}

.testmode .menuInnerRight {
  background: var(--testmode-bg-color);
}

.newMenuInnerRight {
  position: absolute;
  right: 0;
  top: 0;
  padding-right: 24px;
  background: transparent;
  padding-top: 10px;
  z-index: var(--zindex-base-over);
}

.testmode .newMenuInnerRight {
  background: transparent;
}

.menuLoginRight {
  position: absolute;
  right: 2px;
  top: 0;
  padding-right: 14px;
  /*background: var(--title-bg-color);*/
  padding-top: 18px;
  z-index: var(--zindex-base-over);
}

.moveRight15 {
  margin-right: 15px;
}

.logoImg {
  height: 35px;
  position: relative;
  top: 1px;
  left: 10px;
  cursor: pointer;
  filter: var(--logo-filter);
  -webkit-filter: var(--logo-filter);
}

.testmode .logoImg {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);

  /*
  filter: hue-rotate(220deg);
  -webkit-filter: hue-rotate(220deg);
  */
}

.menuSideArr {
  position: absolute;
  top: 7px;
  right: -6px;
}

.menuActionCell {
  position: relative;
  text-align: center;
  cursor: pointer;
  color: #666;
  font-size: 14px;
}

.menuGapCell {
  width: 20px;
}

.mobile .menuGapCell {
  width: 10px;
}

.menuGapCellWide {
  width: 25px;
}

.mobile .menuGapCellWide {
  width: 15px;
}


.menuActionImg {
  height: 20px;
  cursor: pointer;
  opacity: 0.75;
  display: block;
  margin: 0 auto;
  margin-top: 3px;
  margin-bottom: 7px;
}

.menuActionDownImg {
  height: 20px;
  cursor: pointer;
  opacity: 0.75;
  display: block;
  margin: 0 auto;
  margin-top: 4px;
  margin-bottom: 5px;
}

.menuActionMediumImg {
  height: 24px;
  cursor: pointer;
  opacity: 0.75;
  display: block;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 6px;
}

.menuActionBigImg {
  height: 24px;
  cursor: pointer;
  opacity: 0.75;
  display: block;
  margin: 0 auto;
  margin-top: 1px;
  margin-bottom: 5px;
}

.menuActionBiggestImg {
  height: 25px;
  cursor: pointer;
  opacity: 0.75;
  display: block;
  margin: 0 auto;
  margin-top: 0px;
  margin-bottom: 5px;
}

.menuActionCell:hover {
  color: #222;
}

.menuActionCell:hover .menuActionImg {
  opacity: 1;
}

.menuActionCell:hover .menuPolyTri {
  fill: #222;
  stroke:#222;
}


.mobile .menuImg {
  width: 30px;
  height: 30px;
  position: relative;
  top: 2px;
  cursor: pointer;
}

.desktop .menuImg {
  display: none;
}

.testmode .menuImg {
  filter: invert(1.0) grayscale(100%);
  -webkit-filter: invert(1.0) grayscale(100%);
}

.menuFadeOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background: var(--primary-font-color);
  opacity: 0.5;
  z-index: var(--zindex-menu-fade);
}

.menuContentOverlay {
  position: fixed;
  top: 0px;
  right: 0px;
  height: 100%;
  z-index: var(--zindex-menu-content);
  cursor: pointer;
}

.menuContentPanel {
  position: absolute;
  height: 100%;
  background: var(--menu-bg-color);
  color: var(--menu-font-color);
  top: 0px;
  right: 0px;
  cursor: default;
  -webkit-­transition: all 0.5s ease;
  ­-moz-­transition: all 0.5s ease;
  ­-o-­transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.menuProfileSummary {
  padding: 28px 20px 28px 20px;
  width: 220px;
  color: var(--menu-font-color);
  min-height: 80px;
}

.menuProfileSummaryShort {
  padding: 13px 20px 13px 20px;
  width: 220px;
  color: var(--menu-font-color);
  min-height: 80px;
}

.menuOverlayItem {
  background: var(--menu-bg-color);
  padding: 8px 20px 8px 20px;
  width: 220px;
  color: var(--menu-font-color);
  cursor: pointer;
}

.menuOverlayItem:hover {
  color: var(--menu-selected-font-color);
}

.selectedMenuOverlayItem {
  background: var(--menu-selected-bg-color);
  font-weight: bold;
  color: var(--menu-selected-font-color);
}

.showMenuContentPanel {
  width: 260px;
}

.hideMenuContentPanel {
  width: 0px;
}

.showMenuContentOverlay {
  width: 100%;
  visibility: visible;
}

.hideMenuContentOverlay {
  width: 0px;
  visibility: hidden;
}

.userImg {
  width: 80px;
}

.userImgCnt {
  text-align: center;
  margin: 30px;
}

.userInfo {
  text-align: center;
  padding: 0px 0px 8px 0px;
  font-weight: bold;
  color: var(--menu-font-color);
}

.balanceInfo {
  text-align: center;
  padding: 0px 0px 8px 0px;
}

.menuShort .selectedMenuOverlayItem, .menuShort .menuOverlayItem, .menuShort .balanceInfo, .menuShort .userInfo {
  font-size: 14px;
}

.backBtn {
  font-size: 20px;
  padding: 7px 0;
  cursor: pointer;
  color: var(--menu-back-font-color);
  display: inline-block;
}

.testmode .backBtn {
  color: #444;
}

.menuListItem {
  background: var(--menu-bg-color);
  padding: 8px 20px 8px 20px;
  width: 220px;
  color: var(--menu-font-color);
  cursor: pointer;
}

.menuListItem:hover {
  color: var(--menu-selected-font-color);
}

.newMenuBar {
  background-color: #fff;
  height: 64px;
  color: #222;
  position: relative;
  /*position: fixed;*/
  z-index: var(--zindex-menu-bar);
  white-space: nowrap;
  width: 100%;
}

.testmode .newMenuBar {
  background-color: #B3EFFE;
}

.menuTopTitle {
  padding: 20px;
  font-size: 20px;
  text-align: center;
  width: 220px;
}

.newMenuInner {
  margin: 0 14px;
  padding-top: 14px;
}

.loggedin .desktop .newMenuInner {
  margin: 0 32px;
  padding-top: 14px;
}

.newMenuInnerRight {
  position: absolute;
  right: 0px;
  top: 0px;
  padding-right: 24px;
  background: transparent;
  padding-top: 10px;
  z-index: var(--zindex-base-over);
}

.newMenuInnerRightLabels {
  position: absolute;
  right: 0px;
  top: 10px;
  padding-right: 24px;
  background: transparent;
  padding-top: 10px;
  z-index: var(--zindex-base-over);
}

.mobile .newMenuInnerRight, .mobile .newMenuInnerRightLabels {
  top: 12px;
}

.mobile .newMenuInner .logoImg {
  left: 5px;
}

.desktop .newMenuInner .logoImg {
  left: -5px;
}

.menuProfileUsername {
  font-size: 20px;
}

.menuProfileWallet {
  margin-top: 10px;
  font-size: 12px;
}

.menuProfileBalance {
  margin-top: 10px;
  font-size: 12px;
}

.feedbackPrompt {
  position: fixed;
  bottom: 30px;
  right: 30px;
  width: 50px;
  height: 50px;
  z-index: var(--zindex-feedback);
}

.feedbackCircle {
  border-radius: 25px;
  width: 50px;
  height: 50px;
  background: #01c3e3;
  border: 1px solid #01c3e3;
  cursor: pointer;
}

.feedbackImg {
  width: 30px;
  height: 30px;
  margin-top: 10px;
  margin-left: 10px;
}

.menuQuickLinkCnt {
  margin-right: 10px;
}

.menuQuickLinkImg {
  width: 20px;
  padding: 4px;
  vertical-align: top;
  cursor: pointer;
  margin-right: 0px;
}