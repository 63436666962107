.footer {
  background: var(--menu-bg-color);
  padding: 20px 0px 30px 0px;
  text-align: center;
  position: absolute;
  bottom: 0px;
  width: 100%;
}

.footerIcons {
  text-align: center;
}

.footerText {
  text-align: center;
  color: #fff;
}

.footerGreyText {
  text-align: center;
  color:  #888;
}

.footerlink, .footerlink:active, .footerlink:visited {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
}

.footerlink:hover {
  color: var(--button-hover-color);
  text-decoration: underline;
}

.socialMediaImgLarge {
  height: 35px;
  filter: invert(1.0);
}

.socialMediaImgSmall {
  height: 25px;
  filter: invert(1.0);
}