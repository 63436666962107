.questionCnt {
  position: relative;
  display: inline-block;
  height: 36px;
  vertical-align: top;
}

.questionCntTextAligned {
  position: relative;
  display: inline-block;
  height: 0px;
  vertical-align: top;
}

.questionIcon {
  position: relative;
  top: 8px;
  height: 18px;
  width: 18px;
  left: 0;
  cursor: pointer;
}

.questionIconTextAligned {
  position: relative;
  top: 0px;
  height: 18px;
  width: 18px;
  left: 5px;
  cursor: pointer;
}

.questionMsgBox {
  position: absolute;
  border: 1px solid #000;
  background: #ddd;
  font-size: 16px;
  bottom: 10px;
  color: var(--primary-font-color);
  padding: 10px;
  z-index: var(--zindex-question-tooltip);
  white-space: normal;
}

.questionMsgBoxLower {
  position: absolute;
  border: 1px solid #000;
  background: #ddd;
  font-size: 16px;
  top: 35px;
  color: var(--primary-font-color);
  padding: 10px;
  z-index: var(--zindex-question-tooltip);
  white-space: normal;
}

.questionMsgBoxLeft {
  position: absolute;
  border: 1px solid #000;
  background: #ddd;
  font-size: 16px;
  top: -165px;
  color: var(--primary-font-color);
  padding: 10px;
  z-index: var(--zindex-question-tooltip);
  white-space: normal;
}