.statRow {
  margin-bottom: 20px;
}

.statLabel {
}

.statValue {
  font-weight: bold;
  word-break: break-word;
}