.breadLink {
  font-size: 14px;
  cursor: pointer;
  text-decoration: underline;
  color: #999;
}

.breadSep {
  font-size: 14px;
  color: #666;
  font-weight: bold;
}