
.inputLeftSideExplainer {
  position: absolute;
  font-size: 16px;
  color: var(--primary-font-color);
  font-family: system-ui, sans-serif;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid #fff;
  top: 0px;
  left: 0px;
  width: calc(100% - 40px);
}


.inputRightSideExplainer {
  position: absolute;
  font-size: 16px;
  font-family: system-ui, sans-serif;
  background: #fff;
  padding: 8px 15px;
  border: 1px solid #fff;
  top: 0px;
  left: 0px;
  text-align: right;
  width: calc(100% - 40px);
  color: #888;
}

.changeLink {
  position: absolute;
  top: 4px;
  right: 13px;
  z-index: var(--zindex-base-raised);
  font-size: 12px;
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  background: var(--disabled-field-bg-color);
  border: none;
  padding: 8px;
}

.iconPadding {
  padding-left: 35px;
}