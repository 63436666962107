.commentCard {
  position: relative;
  color: var(--primary-font-color);
  border: 1px solid var(--content-border-color);
  background: var(--card-bg-color);
  padding: 17px 20px;
  border-bottom: none;
}

.desktop .commentCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.commentUsername {
  font-weight: bold;
  margin-right: 10px;
}

.commentTimestamp {
  color: var(--light-font-color);
}

.commentContent {
  padding-top: 20px;
}

.commentCornerImg {
  position: absolute;
  top: 13px;
  right: 13px;
  cursor: pointer;
  width: 20px;
  height: 20px;
}