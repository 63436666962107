.newButtonCnt {
  font-size: 20px;
}

.newBtnLeft {
  margin-right: 20px;
}

.newBtnRight {
  margin-right: 10px;
}

.mobile .newBtnLeft, .mobile .newBtnRight {
  margin-right: 10px;
  min-width: 145px;
}