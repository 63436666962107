.appWarningMessageFlt {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  z-index: var(--zindex-warning-bar);
}

.appWarningMessageAbs {
  width: 100%;
  background: transparent;
  color: transparent;
  user-select: none;
}

.appWarningMessageFlt .warningMsgContent {
  background: #FEEFB3;
  padding: 20px;
  color: #9F6000;
}

.appWarningMessageFlt .testMsgContent {
  background: #B3EFFE;
  padding: 20px;
  color: #444;
  /* color: #00609F; */
}

.msgLink {
  cursor: pointer;
}

.msgLink:hover {
  text-decoration: underline;
}

.appWarningMessageAbs .warningMsgContent, .appWarningMessageAbs .testMsgContent {
  padding: 20px;
  background: transparent;
  color: transparent;
  user-select: none;
}
