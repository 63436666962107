.arbitratorList {
  max-width: calc(var(--content-max-width) + 40px);
  color: var(--primary-font-color);
  cursor: default;
  padding: 0px;
  margin-left: auto;
  margin-right: auto;
}

.arbitratorListRow {
  background: var(--card-bg-color);
  border: 1px solid var(--content-border-color);
  cursor: pointer;
}

.arbitratorListRowTop {
  background: var(--card-bg-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  border-top: 1px solid var(--content-border-color-dark);
  cursor: pointer;
}

.arbitratorListRowMiddle {
  background: var(--card-bg-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  cursor: pointer;
}

.arbitratorListRowBottom {
  background: var(--card-bg-color);
  border-left: 1px solid var(--content-border-color);
  border-right: 1px solid var(--content-border-color);
  border-bottom: 1px solid var(--content-border-color-dark);
  cursor: pointer;
}

.arbitratorListCell {
  padding: 5px 0px 10px 20px;
  vertical-align: top;
}

.arbitratorListPhoto {
  width: 48px;
  padding: 1px;
  vertical-align: top;
}

.arbitratorListUsername {
  padding: 10px 0px 10px 10px;
  display: inline-block;
  vertical-align: top;
}

.arbitratorListRowMiddle .arbitratorListCell {
  padding: 5px 0px 0px 20px;
}

.arbitratorListRowTop .arbitratorListCell {
  padding: 5px 0px 0px 20px;
}

.primaryStat {
  font-size: 20px;
}

.smallStatLabel {
  font-size: 14px;
  color: #888;
}

.socialMediaSmallImg {
  width: 25px;
  height: 25px;
  vertical-align: middle;
  border: 1px solid transparent;
  border-radius: 2px;
  position: relative;
  left: -8px;
  top: -1px;
}
