.verificationMsg {
  background: #eee;
  border: 1px solid #ccc;
  color: var(--primary-font-color);
  padding: 8px 15px;
  display: table-cell;
}

.yourDefaultBadge {
  padding: 3px 15px 0px 3px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #666;
  display: inline-block;
  margin-top: 5px;
}