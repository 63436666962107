.copyTextArea {
  border: none;
  overflow: auto;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  resize: none;
  width: 100%;
  background: #f9f9f9;
  cursor: pointer;
}

.copySuccessMsg {
  font-size: 12px;
  color: var(--primary-font-color);
}