.tableCnt {
  width: 100%;
  width: calc(100% + 40px);
  border-collapse: separate;
  border-spacing: 20px 0px;
  margin-left: -20px;
}

.tableCntBorder {
  width: calc(100% + 40px);
  border-collapse: separate;
  border-spacing: 20px 20px;
  margin-left: -20px;
  margin-top: -20px;
}

.tdLeft {
  width: 50%;
  position: relative;
  vertical-align: top;
}

.tdRight {
  width: 50%;
  position: relative;
  vertical-align: top;
}

.tdFull {
  width: 100%;
  position: relative;
  vertical-align: top;
}

.tdMiddleLeftThree {
  width: 66%;
  position: relative;
  vertical-align: top;
}

.tdLeftThree {
  width: 33%;
  position: relative;
  vertical-align: top;
}

.tdRightThree {
  width: 33%;
  position: relative;
  vertical-align: top;
}

.tdMiddleThree {
  width: 33%;
  position: relative;
  vertical-align: top;
}

.tdFourCol1, .tdFourCol2, .tdFourCol3, .tdFourCol4 {
  width: 25%;
  position: relative;
  vertical-align: top;
}

.tableCntBorder td {
  border: 1px solid #eee;
  background: #fff;
}

.tableSingleColumn {
  max-width: 530px;
}