.verifyCnt {
  height: 100%;
  width: 100%;
  position: absolute;
  background: #222;
}

.verifyWrap {
  padding: 5px;
  width: calc(100% - 10px);
  height: calc(100% - 10px);
}

.verifyBox {
  padding: 20px;
  background: #444;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  position: relative;
}

.logoVerifyImg {
  height: 30px;
  position: relative;
}

.verifyMessage {
  margin-top: 20px;
  font-size: 16px;
}
