.verifiedMsg {
  background: #eee;
  border: 1px solid #ccc;
  color: var(--primary-font-color);
  padding: 8px 15px;
  display: table-cell;
}

.attestationWrapValue {
  font-weight: bold;
  word-wrap: break-word;
}