.lineCard {
  height: 0px;
  border-top: 1px solid var(--content-border-color);
  background: transparent;
}

.desktop .lineCard {
  max-width: 1042px;
  margin-left: auto;
  margin-right: auto;
}