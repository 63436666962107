.paginationCard {
  color: var(--primary-font-color);
  font-size: 16px;
  padding: 0px 20px;
  height: 30px;
}

.desktop .paginationCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}
