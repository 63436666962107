.locationSearchInput {
  width: calc(100% - 40px);
}

.locationResult, .categoryResult {
  display: inline-block;
  cursor: pointer;
  color: var(--link-color);
  text-decoration: none;
}
