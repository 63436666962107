/*
.statusCard {
  color: #fff;
  border: 1px solid var(--title-bg-color);
  font-size: 16px;
  padding: 5px 20px;
  background: var(--title-bg-color);
}



.overlayDialog .statusCard {
  margin-top: 0;
}
*/

.statusCard {
  padding: 0px 20px;
}

.desktop .statusCard {
  max-width: var(--content-max-width);
  margin-left: auto;
  margin-right: auto;
}

.mobile .statusCard {
  display: none;
}

.statusStep {
  font-size: 12px;
  color: #444;
  display: inline-block;
}

.statusStepSelected {
  font-size: 12px;
  text-decoration: underline;
  color: #444;
  font-weight: bold;
  display: inline-block;
}

.statusStepArrow {
  font-size: 24px;
  color: #222;
  font-weight: bold;
  display: inline-block;
}
