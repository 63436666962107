.ratingsLikeIcon {
  width: 24px;
  position: relative;
  right: 0px;
  top: 2px;
  opacity: 0.75;
}

.ratingsDislikeIcon {
  width: 24px;
  position: relative;
  right: 0px;
  top: 8px;
  opacity: 0.75;
}

.ratingsText {
  padding-left: 5px;
}

.ratingsLink {
  cursor: pointer;
  font-weight: bold;
}

.reviewText {
  font-style: italic;
  color: #666;
}
