.accountBalanceTable {
  width: 100%;
}

.accountBalanceTable td {
  padding: 10px;
}

.accountBalanceCell {
  width: 1px;
  white-space: nowrap;
}