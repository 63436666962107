.gapCard, .gapCardHideMobile, .gapCardHideDesktop {
  background: transparent;
}

.desktop .gapCard, .desktop .gapCardHideMobile {
  height: var(--gap-size-desktop);
  max-width: calc(var(--content-max-width) + 40px);
  margin-left: auto;
  margin-right: auto;
}

.mobile .gapCard, .mobile .gapCardHideDesktop {
  height: var(--gap-size-mobile);
}

.smallGapCard {
  background: transparent;
  height: var(--gap-size-mobile);
}
